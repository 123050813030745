@import '../../../assets/sass/theme.scss';


.ctp-footer {
    background: #121212 !important;
    padding: 60px 0px 30px 0px;
}

.token-name {
    color: $gold;
    font-weight: 700;
    font-size: 22px;
}

.buy-token-but {
    border: 1px solid $gold;
    border-radius: 5px;
    width: 120px;
    padding: 10px;
    text-align: center;
    cursor: pointer;
    font-weight: 600;
    transition: .3s all ease-in-out;

    &:hover {
        background: $gold;
        color: $background-color
    }
}

.made-by {
    color: white !important;
}

.footer-divider {
    background: $background-color;
    margin: 0;
    padding: 0;
    box-shadow: 0;
}