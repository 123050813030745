@import '../../assets/sass/theme.scss';

.home-section {
    background-image: url('../../assets/img/cloud.png');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
}

.ctp-button {
    height: 40px;
    background: #323232;
    border: none;
    border-bottom: 2px solid $primary;
    border-radius: 15px;
    color: white;
    padding: 0 20px;

    font-size: 16px;
    font-weight: 500;

    transition: .3s all ease-in-out;

    &:hover {
        transform: translateY(-5px);
    }
}

.social-panel {
    width: 80%;
    background-image: linear-gradient(270deg, rgba(85, 85, 85, 0.2) 30%, rgba(0, 0, 0, 0) 100%);
    border-radius: 0px 15px 15px 0px;
    padding: 20px;

    @media (max-width: 992px) {
        width: 100%;
        padding: 20px 10px;
    }
}

.social-ico {
    border-radius: 8px;
    background: rgba(217, 217, 217, 0.05);
    width: 50px;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.pp-section {
    position: relative;
}

.pp-section .section-head h2:before {
    opacity: 0.06;
    font-size: 120px;
    font-weight: 800;
    background-image: linear-gradient(180deg, #1a1a1a 0%, #eef3fc 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    position: absolute;
    left: 50%;
    top: 20px;
    content: attr(title);
    font-family: "Roboto", sans-serif;
    line-height: .9;
    text-transform: uppercase;
    white-space: nowrap;
    color: #202b47;
    transform: translate(-50%, -50%);

    @media (max-width: 768px) {
        display: none;
    }
}

.title {
    display: flex;
    justify-content: center;
    font-family: "Roboto", sans-serif;
    font-weight: 700;
}

.seed-investor-section {
    background-image: url('../../assets/img/seed-investor-shiny-back.png');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    padding: 60px 0px;
}

.section-title {
    font-weight: 800;
    font-size: 50px;
    line-height: 48px;
}

.seed-investor-guilde-line-step-left {
    background: linear-gradient(90deg, rgba(85, 85, 85, 0.2) 30%, rgba(0, 0, 0, 0) 100%);
    border-radius: 10px 0px 0px 10px;
    padding: 15px 25px;
    display: flex;
    align-items: center;

    .seed-investor-guilde-line-step-number {
        font-weight: 900;
        font-size: 52px;
        color: #00000059;
    }
}

.future-projects-card {
    background: linear-gradient(180deg, rgba(1, 161, 159, 1) 0%, rgba(1, 161, 159, 0.5) 100%);
    border-radius: 20px;
    padding: 30px;
}

.project-card {
    width: 360px;
    height: 240px;
    background: rgba(0, 0, 0, 0.3);
    border-radius: 15px;
    background-size: contain;
}

.incubate-guidline-step {
    border-radius: 15px;
    padding: 40px 10px 40px 40px;
    color: $primary-font-color;
    font-size: 16px;
    font-weight: 400;
    height: 100%;
    display: flex;
    align-items: center;
    margin-left: 20px;
    background: #2e2c2c;

    .incubate-guidline-step-number {
        position: absolute;
        width: 60px;
        height: 50px;
        left: 5px;
        background-color: $background-color;
        color: white;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 15px;
        font-size: 36px;
        font-weight: 700;
    }
}

.incubated-project-card {
    margin: 10px;
    background: $background-color;
    border-width: 2px 0px 0px 2px;
    border-style: solid;
    border-color: $gold;
    box-shadow: 6px 6px 0px $primary;
    border-radius: 5px;

    transition: .5s all ease-in-out;

    &:hover {
        transform: translate(-3px, -3px);
        box-shadow: 12px 12px 0px $primary;
    }
}

.token-info {
    font-size: 22px;
    font-weight: 600;
}

.ctp-customized-progressbar {
    background-color: rgba(0, 0, 0, 0.25) !important;

    .progress-bar {
        background-color: $primary !important;
        border-radius: 20px !important;
    }

    border-radius: 20px !important;
    height: 5px !important;
}

.roadmap-divider {
    border-bottom: 1px dashed white;
}

.roadmap-phase {
    padding: 20px 30px 40px 30px;
    background: rgba(26, 26, 26, 0.15);
    border-width: 1px 0px 2px 0px;
    border-style: solid;
    border-color: $gold;
    box-shadow: 0px 0px 20px rgba(115, 115, 115, 0.25);
    border-radius: 15px;
    font-size: 18px;
}

.team-section {
    background-image: url('../../assets/img/world.png');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
}

.team-member {
    background: linear-gradient(90deg, rgba(85, 85, 85, 0.2) 30%, rgba(0, 0, 0, 0) 100%);
    border: 1px solid $primary;
    border-radius: 15px 0px 0px 15px;
    padding: 30px;
    display: flex;

    .photo {
        width: 140px;
        height: 210px;
        background: #161616;
        // border: 1px solid #00FFFF;
        border-radius: 20px;
    }

    .name {
        font-size: 30px;
        font-weight: 600;
    }

    .role {
        font-size: 24px;
        font-weight: 500;
    }

    .bio {
        font-size: 16px;
        font-weight: 400;
        line-height: 22px;
    }

    .social {
        font-size: 24px;
    }
}

.token-symbol {
    width: 100px;
    height: 100px;

    display: flex;
    align-items: center;
    justify-content: center;

    img {
        width: 100%;
        border-radius: 150px;
    }
}

.custom-accordion {
    background: #202020;
    border-bottom: 3px solid $primary;
    border-radius: 5px;
    padding: 20px 20px;
    font-size: 16px;

    .accordion-header {
        padding: 0;
        width: 100%;
        border: none;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .accordion-button {
        color: white;
        box-shadow: none !important;
        border: none;
        width: 100%;
        font-size: 18px;
        font-weight: 500;
        background: transparent;
        display: flex;
        align-items: center;
        justify-content: center;
    }
}

.faq-symbol {
    display: flex;
    justify-content: center;

    @media (max-width: 576px) {
        // display: none;
    }
}

.in-line__icon {
    padding: 0 20px 0 32px;
    background-image: url('../../assets/img/microphone.svg');
    background-position: 0 50%;
    background-size: 56px 56px;
    background-repeat: no-repeat;
}

.explore-community-title {
    font-size: 60px;
    font-weight: 800;
    color: white;

    @media (max-width: 576px) {
        font-size: 40px;
    }
}

.explore-social-icon {
    margin: 20px;
    font-size: 40px;
    transition: .3s all ease-in-out;
    cursor: pointer;

    &:hover {
        transform: translateY(-10px);
    }
}

.explore-social-card {
    background: #2e2c2c;
    border-radius: 20px;
    max-width: 380px;
    padding: 30px 30px 40px 30px;
    cursor: pointer;

    &:hover {
        background: $primary;
        color: $background-color;
    }
}

.primary-border {
    border-color: $primary !important;
}

.social-link-a {
    color: $primary-font-color;

    &:hover {
        color: $primary-font-color;
        text-decoration: none;
    }
}

.burn-phase {
    background: rgba(0, 0, 0, 0.5);
    height: 100%;
    padding: 40px 30px 60px 30px;
    cursor: pointer;

    p {
        font-size: 28px;
        font-weight: 700;
    }

    div {
        font-size: 16px;
    }
}

.hover-color-gold {
    transition: .4s all ease-in-out;

    &:hover {
        background-color: $gold;
        color: $background-color;
    }
}

.hover-color-primary {
    transition: .4s all ease-in-out;

    &:hover {
        background-color: $primary;
        color: white;
    }
}

.profile-explorer-social {
    &:hover {
        color: $primary;
    }
}

.big-burn-section {
    background-image: url('../../assets/img/fire.png');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
}

.whitepaper-but {
    width: 180px;
}

.whitepaper-but::after {
    content: "Read Whitepaper";
}

.whitepaper-but:hover::after {
    content: "Coming soon";
}