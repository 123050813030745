@import '../../assets/sass/theme.scss';


.fade-in-section {
    opacity: 0;
    transform: translateY(30%);
    visibility: hidden;
    transition: opacity 0.3s ease-in-out, transform 0.6s ease-in-out;
    will-change: opacity, visibility;
}

.fade-in-section.is-visible {
    opacity: 1;
    transform: none;
    visibility: visible;
}

.corner-border {
    position: relative;
}

.corner-border:before,
.corner-border:after,
.corner-border> :first-child:before,
.corner-border> :first-child:after {
    position: absolute;
    width: 34px;
    height: 34px;
    border-color: $primary;
    border-style: solid;
    content: ' ';
    transition: right 0.9s linear,
        border-left-color 0.9s cubic-bezier(1, -0.38, 1, -0.44), left 0.9s linear,
        border-right-color 0.9s cubic-bezier(1, -0.38, 1, -0.44);
}

.left-top-corner.corner-border:before {
    top: 0;
    right: 0;
    border-left-color: rgba(255, 255, 255, 0);
    border-width: 1px 0px 0px 1px;
}

.right-top-corner.corner-border:after {
    top: 0;
    left: 0;
    border-width: 1px 1px 0 0;
    border-right-color: rgba(255, 255, 255, 0);
}

.right-bottom-corner.corner-border> :first-child:before {
    bottom: 0;
    left: 0;
    border-width: 0 1px 1px 0;
    border-right-color: rgba(255, 255, 255, 0);
}

.left-bottom-corner.corner-border> :first-child:after {
    bottom: 0;
    right: 0;
    border-width: 0 0 1px 1px;
    border-left-color: rgba(255, 255, 255, 0);
}

.is-visible.left-top-corner.corner-border:before {
    right: 100%;
    border-left-color: $primary;
}

.is-visible.right-top-corner.corner-border:after {
    left: 100%;
    border-right-color: $primary;
}

.is-visible.right-bottom-corner.corner-border> :first-child:before {
    left: 100%;
    border-right-color: $primary;
}

.is-visible.left-bottom-corner.corner-border> :first-child:after {
    right: 100%;
    border-left-color: $primary;
}

@media (max-width: 768px) {

    .corner-border:before,
    .corner-border:after,
    .corner-border> :first-child:before,
    .corner-border> :first-child:after {
        width: 0px;
        height: 0px;
    }
}