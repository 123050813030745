@import '../../../assets/sass/theme.scss';

.ctp-navbar {
    background-color: $background-color;
    border: none;
    box-shadow: 0px 1px 4px 0 #0000008a;
}

.custom-navbar-button {
    margin: 0 0.8rem;
    padding: 0.6rem 0;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    // text-align: center;
    font-size: 15px;
    font-weight: 400;
    text-decoration: none !important;
    transition: all 0.2s ease-in;
    color: $primary-font-color !important;
    background-color: transparent;

    &:hover {
        color: $primary !important;
    }
}

.navbar-toggler {
    padding: 3px 5px;
}

.auth-button {
    width: 150px;
    margin-left: 1rem;
    margin-right: 0rem;
    color: white !important;
    background: $primary;
    border-radius: 5px;

    span {
        font-weight: 500;
    }

    transition: .3s all ease-in-out;

    &:hover {
        color: $background-color !important;
        background: #fae447;
    }
}

@media (max-width: 992px) {
    .nav-menu-wrap.navbar-collapse.collapse.show {
        margin-top: 1rem;
    }

    .auth-button {
        margin: auto;
        margin-top: 1rem;
    }

    .sub-drop-down {
        padding-left: 8px;
    }
}

.ctp-logo {
    width: auto;
    height: 55px;

    @media (max-width: 992px) {
        height: 45px;
    }
}

.ctp-logo-title {
    margin-left: 15px;

    .dApp-name {
        color: $gold;
        font-weight: 700;
        font-size: 24px;
    }

    .dApp-slogan {
        color: white;
        font-size: 12px;
    }

    @media (max-width: 992px) {
        margin-left: 10px;

        .dApp-name {
            font-weight: 600;
            font-size: 17px;
        }

        .dApp-slogan {
            font-size: 11px;
        }
    }
}

.dropdown-menu {
    animation: growDown 500ms ease-in-out forwards;
    transform-origin: top center;

    background: #ffffff1c;

    width: 150px;

    a {
        color: $primary-font-color;

        &:hover {
            color: black;
            // background-color: $primary;
        }
    }
}

@keyframes growDown {
    0% {
        transform: scaleY(0);
    }

    80% {
        transform: scaleY(1.1);
    }

    100% {
        transform: scaleY(1);
    }
}

.dropdown-item:active {
    background-color: $primary !important;
}


.dropdown-toggle {
    color: $primary-font-color !important;
}

.sub-drop-down .dropdown-menu {
    cursor: pointer;
    position: absolute !important;
    left: 10em;
    top: 0em;
}

.sub-drop-down .dropdown-toggle {
    color: $primary-font-color !important;
}

.nav-menu-box {
    margin-left: auto;
    display: flex;
    align-items: center;

    @media (max-width: 992px) {
        display: flex;
        align-items: flex-start;
    }
}

.addition-bar {
    background-color: $background-color;
    display: flex;
    justify-content: flex-end;
    border-bottom: 1px solid $background-color;
    box-shadow: 0x 0x 0 $background-color;

    @media (max-width: 992px) {
        justify-content: center;
    }
}

.addition-but {
    padding: 8px;
    background-color: #fae447;
    color: $background-color;
    border-radius: 5px;
    font-size: 1rem;
    transition: .4s all ease-in-out;
    opacity: 0;
    cursor: pointer;
}

.addition-bar-displaying .addition-but {
    opacity: 1;
}

.additional-but-box {
    display: flex;
    gap: 10px;
    padding-right: 36px;

    @media (max-width: 992px) {
        padding-right: 0px;
    }
}

#nav-dropdown-dark-example.nav-link {
    padding: 0px !important;
}