.incubator-section {
    // background-image: url('../../../assets/img/cloud.png');
    // background-size: cover;
    // background-repeat: no-repeat;
    // background-position: center;

    .action-button {
        width: 140px;
        height: 50px;
        padding: 5px 10px;
        border-radius: 6px;
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
        // font-family: 'Inter';
        font-weight: 500;
        background: #2A2A2A;
        color: white;
        cursor: pointer;
    }

    a:hover {
        text-decoration: none !important;
    }

    .action-active-button {
        background: #F5DA3B;
        color: black;
    }

    .project-container {
        width: 350px;
        background-color: #222121;
        border: 1px solid #222121;
        border-radius: 10px;
        position: relative;
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 0px 15px 50px 15px;
        box-shadow: 0px 4px 4px 0px #00000040;

        .project-logo {
            background: white;
            width: 92px;
            height: 92px;
            border-radius: 10px;
            display: flex;
            justify-content: center;
            align-items: center;
            margin-top: -35px;
        }

        .project-name {
            color: white;
            font-size: 28px;
            font-weight: 700;
            line-height: 34px;
            margin: 30px 0px 20px 0px;
        }

        .project-description {
            color: #535353;
            font-size: 15px;
            font-weight: 400;
            line-height: 16.5px;
            // font-family: 'Manrope';
            text-align: center;
        }

        .project-cap {
            width: 100%;
            background: #1D1D1D;
            padding: 10px;
            border-radius: 6px;
            gap: 10px;
            display: flex;
            justify-content: space-between;
            .project-cap-value {
                color: #F5DA3B;
            }
        }

        .project-social {
            color: #535353;
            font-weight: 400;
            font-size: 16px;
            .project-socal-logos {
                display: flex;
            }
        }

        .project-apply {
            position: absolute;
            cursor: pointer;
            background: #F5DA3B;
            width: 150px;
            height: 47px;
            border-radius: 6px;
            padding: 10px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            color: #1E1E1E;
            top: 95%;
        }

        .project-unlock {
            background: #67B800;
            color: black;
            div {
                line-height: 110%;
                font-weight: 500;
            }
            div:nth-child(2) {
                font-size: 12px;
                font-weight: 400;
            }
        }
    }
}

.purchase-modal {
    .purchase-button {
        margin-top: 20px;
        background: #F5DA3B;
        cursor: pointer;
        width: 130px;
        height: 40px;
        border-radius: 6px;
        padding: 10px;
        display: flex;
        justify-content: center;
        align-items: center;
        color: #1E1E1E;
    }
    
    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
        /* display: none; <- Crashes Chrome on hover */
        -webkit-appearance: none;
        margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
    }
    
    input[type=number] {
        -moz-appearance:textfield; /* Firefox */
    }

    a {
        color: white;
    }
    a:hover {
        color: white;
        text-decoration: none !important;
    }
}
